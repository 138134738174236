import React, { useState, useEffect } from "react";
import UnderMaintenance from "./UnderMaintenance";
import useDocumentTitle from "../Components/UseDocumentTitle";
import FrontPage from "../Components/Services/FrontPage";
import ConceptService from "../Components/Services/ConceptService";
import {
  NewsArticles,
  TitledFrontPageStyled,
} from "../Components/Services/style";
import { Link } from "react-router-dom";
import axios from "axios";
import PaginationBlog from "../Components/Pagination";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const News = () => {
  const [path, setPath] = useState(window.location.pathname);
  const [articles, setArticles] = useState([]);
  const [paginas, setPaginas] = useState(0);
  const [loading, setLoading] = useState(true);

  useDocumentTitle("News | 3D World renderings, Inc.");
  useEffect(() => {
    const config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    };
    axios
      .get("https://panelblog.3dworld.nyc/blog/list", config)
      .then(function (response) {
        window.scrollTo(0, 0);
        setArticles(response.data.data);
        setPaginas(response.data.last_page);
        setLoading(false);
      });
    if (window.innerWidth > 1024) {
      const activeNot = document.getElementsByClassName("activeService");
      if (activeNot.length == 1) {
        activeNot[0].classList.remove("activeService");
      }

      const active = document.getElementById(path.split("/").join(""));
      active.classList.add("activeService");
    }
  }, []);

  const renderArticlesContent = () => {
    const skeletonArticles = (
      <NewsArticles>
        <div className="content-card">
          <div className="card">
            <div className="img">
              <Skeleton baseColor="#c5c3c3" height={380} />
            </div>
            <p>
              <Skeleton baseColor="#c5c3c3" />
            </p>
          </div>
          <div className="card">
            <div className="img">
              <Skeleton baseColor="#c5c3c3" height={380} />
            </div>
            <p>
              <Skeleton baseColor="#c5c3c3" />
            </p>
          </div>
        </div>
      </NewsArticles>
    );

    if (loading) return skeletonArticles;

    return articles.map((articulo, index) => {
      return (
        <div key={index} className="card">
          <div className="img">
            <Link key={index} to={`/News/${articulo.slug_article}`}>
              <img
                key={index}
                src={`https://panelblog.3dworld.nyc${articulo.url_front}`}
                alt=""
              />
            </Link>
          </div>
          <p>
            <Link
              className="link-subrayado"
              key={index}
              to={`/News/${articulo.slug_article}`}
            >
              {articulo.title_article}
            </Link>
          </p>
        </div>
      );
    });
  };

  return (
    <div className="body-content">
      <TitledFrontPageStyled>
        <FrontPage
          alt={"NYC Skyline View - 3D World renderings, Inc"}
          column={"flex-column"}
          portada={"/assets/img/services/news.jpg"}
          name={"Our Weekly"}
          subtitule={"Newsletter"}
        />
      </TitledFrontPageStyled>
      <NewsArticles>
        <div className="content-card">{renderArticlesContent()}</div>
        {articles.length > 0 && (
          <PaginationBlog setArticles={setArticles} paginas={paginas} />
        )}
      </NewsArticles>
    </div>
  );
};

export default News;
