import styled from "styled-components";

export const ButtonLink = styled.button`
  text-decoration: none;
  font-family: "HelveticaNeuemedium";
  padding: 4px 7px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  display: inline-flex;
  font-size: 22px;
  font-weight: 700;
  border: none;
  position: relative;

  /* Linea amarilla */
  &::after {
    content: " ";
    width: 100%;
    height: 4px;
    background-color: #ffff02;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    transition: height 0.5s ease-in-out;
    z-index: 1;
  }

  &:hover::after {
    height: 9px;
  }

  a {
    font-family: "HelveticaNeueBold";
    font-size: inherit;
    font-weight: inherit;
    position: relative;
    font-weight: 700;
    letter-spacing: -0.03em;
    z-index: 2;
    @media (max-width: 550px) {
      font-size: 20px;
    }
  }
`;

export const OurExperienceCarouselContainer = styled.div`
  background-color: black;
  padding: 70px 35px;
  color: white;
  text-align: center;
  display: none;
  @media (max-width: 550px) {
    display: block;
    padding: 70px 42px;
  }
  @media (max-width: 380px) {
    padding: 70px 24px;
  }
  @media (max-width: 360px) {
    padding: 70px 17px;
  }
  h3 {
    font-size: 35px;
    margin-bottom: 30px;
    letter-spacing: -0.03em;
    font-family: "HelveticaNeueBold";
    font-weight: 700;
    @media (max-width: 550px) {
      margin-bottom: 40px;
    }
  }
  h3,
  p {
    color: white;
  }
  p {
    font-size: 17px;
    font-weight: 400;
    letter-spacing: -0.03em;
    @media (min-width: 1600px) {
      font-size: 20px;
      line-height: 23.47px;
    }
    @media (max-width: 390px) {
      font-size: 17px;
      line-height: 19.09px;
    }
  }
  .swiper-wrapper {
    height: auto;
    padding-bottom: 48px;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
  }
  .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.7);
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 9px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 9px)
    );
  }
  .swiper-pagination-bullet-active {
    background-color: white;
  }
`;

export const ContentPhilosophyMobile = styled.div`
  background-color: rgba(241, 240, 235, 1);
  padding: 41px 30px 60px;
  text-align: center;
  display: none;
  @media (max-width: 550px) {
    display: block;
    margin: 0 15px 0 15px;
    padding: 41px 43px 60px;
  }
  @media (max-width: 390px) {
    padding: 41px 26px 60px;
  }
  h2 {
    @media (max-width: 550px) {
      color: black;
      font-size: 35px;
      font-family: "HelveticaNeueBold";
      display: flex;
      flex-direction: column;
      font-weight: 700;
      &::after {
        content: " ";
        margin: 46px 0;
        width: 100px;
        height: 2px;
        background-color: black;
        align-self: center;
      }
    }
  }
  .title-philosophy {
    @media (max-width: 550px) {
      color: black;
      font-size: 18px;
      font-family: "NeueHaasDisplayMedium";
      font-weight: 700;
      color: rgba(108, 108, 108, 1);
      padding-bottom: 25px;
    }
  }
  .content-philosophy {
    @media (max-width: 550px) {
      font-size: 17px;
      color: rgba(108, 108, 108, 1);
    }
    @media (max-width: 390px) {
      font-size: 16px;
    }
  }
  .swiper-wrapper {
    height: auto;
    padding-bottom: 48px;
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }
  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 8px);
  }
  .swiper-pagination-bullet {
    background-color: rgba(0, 0, 0, 0.3);
    width: var(
      --swiper-pagination-bullet-width,
      var(--swiper-pagination-bullet-size, 9px)
    );
    height: var(
      --swiper-pagination-bullet-height,
      var(--swiper-pagination-bullet-size, 9px)
    );
  }
  .swiper-pagination-bullet-active {
    background-color: rgba(0, 0, 0, 1);
  }
`;
