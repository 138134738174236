import React from "react";
import { PortadaV2 } from "./style";

const FrontPage = (props) => {
  return (
    <PortadaV2>
      <div className="background-black"></div>
      <img
        style={{ objectPosition: props.coverPosition ?? "center" }}
        src={props.portada}
        alt={props.alt}
      />
      <div className="content">
        <div className={`title ${props.column}`}>
          <h1>{props.name}</h1>
          <h2>{props.subtitule}</h2>
        </div>
        <div className="content-description">{props.children}</div>
      </div>
    </PortadaV2>
  );
};

export default FrontPage;
