import React, { useState, useEffect } from "react";
import FrontPage from "../Components/Services/FrontPage";
import { FormContact } from "../Styles/Styles";
import {
  useFormik,
  Formik,
  Form,
  Field,
  ErrorMessage,
  setFieldValue,
} from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "../Components/UseDocumentTitle";
import {
  HeadingBlock,
  HeadingTitle,
  HeadingSubtitle,
} from "../Styles/StyledContact";
import { TitledFrontPageStyled } from "../Components/Services/style";

const Contact = () => {
  const [recaptcha, setRecaptcha] = useState("");
  const [errorRecaptcha, showError] = useState("ocultar-error");
  const [fielSelected, setFielSelected] = useState(0);
  const [path, setPath] = useState(window.location.pathname);
  const [imageSrc, setImageSrc] = useState(
    "/assets/img/services/contacto2.jpg"
  );
  const navigate = useNavigate();
  const [txt_submit, setTxtSubmit] = useState("Submit");
  useDocumentTitle("Contact | 3D World renderings, Inc.");
  const onChange = (val) => {
    setRecaptcha(val);
  };
  // console.log(window.location)
  const SignupSchema = Yup.object().shape({
    name: Yup.string().required("required field"),
    phone: Yup.string()
      .min(10, "Must be at least 10 digits")
      .max(10, "Must be 10 digits maximum")
      .required("required field"),
    email: Yup.string().email("invalid email").required("required field"),
    referencia: Yup.string().required("required field"),
    contacted: Yup.string().required("required field"),
    services: Yup.array().min(1).required("required field"),
    project: Yup.string().required("required field"),
    file: Yup.mixed().test(
      "fileSize",
      "File Size is too large max 5M",
      (value) => {
        // console.log(value)
        if (value && value?.length > 0) {
          // console.log("first if")
          for (let i = 0; i < value.length; i++) {
            // console.log("for",value[i])
            if (value[i].size > 5000000) {
              // console.log("supero",value[i].size)
              return false;
            }
          }
        }
        return true;
      }
    ),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const activeNot = document.getElementsByClassName("activeService");
    if (activeNot.length == 1) {
      activeNot[0].classList.remove("activeService");
    }
    const activeNot2 = document.getElementsByClassName("activeli");
    if (activeNot2.length == 1) {
      activeNot2[0].classList.remove("activeli");
    }
    const active = document.getElementById(path.split("/").join(""));
    active.classList.add("activeService");
  }, []);
  useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth <= 550) {
        setImageSrc("/assets/img/services/Hero-contact.png");
      } else {
        setImageSrc("/assets/img/services/contacto2.jpg");
      }
    };

    window.addEventListener("resize", updateImage);
    updateImage();

    return () => window.removeEventListener("resize", updateImage);
  }, []);
  return (
    <div className="body-content">
      <TitledFrontPageStyled>
        <FrontPage
          alt={
            "A New York City creative studio specializing in 3D rendering, branding, and marketing campaigns - 3D World renderings, Inc.jpg"
          }
          portada={imageSrc}
          name={"Let’s Get "}
          subtitule={"Started"}
        ></FrontPage>
      </TitledFrontPageStyled>
      <HeadingBlock>
        <HeadingTitle>Talk to a Human</HeadingTitle>
        <HeadingSubtitle>
          Please fill out the following form if you are are a developer,
          builder, architect, designer, homeowner, event planner, branding
          and/or marketing agency looking to take your conceptual idea to the
          next level, we can help your business reach its goals.
        </HeadingSubtitle>
      </HeadingBlock>
      <FormContact>
        <div className="content-block">
          <div className="title">
            <h2>Talk to a Human</h2>
          </div>
          <p>
            Please fill out the following form if you are are a developer,
            builder, architect, designer, homeowner, event planner, branding
            and/or marketing agency looking to take your conceptual idea to the
            next level, we can help your business reach its goals.
          </p>
        </div>

        <Formik
          initialValues={{
            name: "",
            phone: "",
            email: "",
            referencia: "",
            contacted: "",
            services: [],
            project: "",
            file: null,
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values, actions) => {
            const form = document.querySelector("form");
            const formData = new FormData(form);
            formData.append("recaptcha", recaptcha);
            formData.append("servicios", values.services);
            if (recaptcha !== "") {
              showError("ocultar-error");
              // swal("Sending", "The mail is being sent", "success");
              setTxtSubmit("Sending...");
              const res = await axios.post(
                process.env.REACT_APP_CONTACT_FORM_URL,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              // console.log(res,'respuesta')
              if (res.data.success) {
                // console.log('sucess')
                navigate("/ThankYou");
                // swal("Sent", "The mail has been sent correctly", "success");
                // actions.resetForm();
              } else {
                if (res.data.error) {
                  swal(
                    "Error",
                    "There was a problem sending the email",
                    "error"
                  );
                  actions.resetForm();
                }
              }
            } else {
              showError("error");
            }
          }}
        >
          {({ errors, touched, setFieldValue, value }) => (
            <Form method="POST" encType="multipart/form-data">
              <Field id="name" type="text" name="name" placeholder="Name*" />
              <ErrorMessage name="name" />
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Email*"
              />
              <ErrorMessage name="email" />
              <Field
                id="phone"
                name="phone"
                type="number"
                placeholder="Phone*"
              />
              <ErrorMessage name="phone" />
              <label htmlFor="referencia">How did you hear about us?*</label>
              <Field id="referencia" name="referencia" as="select">
                <option value="">Select one </option>
                <option value="We've done business before">
                  We've done business before
                </option>
                <option value="Referal">Referal</option>
                <option value="Google">Google</option>
                <option value="Instagram">Instagram</option>
                <option value="Linkedin ">Linkedin </option>
                <option value="Newsletter ">Newsletter </option>
              </Field>
              <ErrorMessage name="referencia" />
              <label htmlFor="contacted">
                What's the best way to contact you?*
              </label>
              <Field id="contacted" name="contacted" as="select">
                <option value="">Select one </option>
                <option value="Email">Email</option>
                <option value="Phone">Phone</option>
              </Field>
              <ErrorMessage name="contacted" />
              <div id="checkbox-group">
                What services are you interested in?
              </div>
              <div
                className="checkbox"
                role="group"
                aria-labelledby="checkbox-group"
              >
                <label>
                  <Field type="checkbox" name="services" value="Rendering" />
                  Rendering
                </label>
                <label>
                  <Field type="checkbox" name="services" value="Animation" />
                  Animation
                </label>
                <label>
                  <Field
                    type="checkbox"
                    name="services"
                    value="Virtual Reality"
                  />
                  Virtual Reality
                </label>
                <label>
                  <Field type="checkbox" name="services" value="Interactive" />
                  Interactive
                </label>
                <label>
                  <Field type="checkbox" name="services" value="Branding" />
                  Branding
                </label>
                <label>
                  <Field type="checkbox" name="services" value="VFX" />
                  VFX
                </label>
              </div>
              <ErrorMessage name="services" />
              <label htmlFor="">Tell us about your project</label>
              <Field name="project" as="textarea" row="3"></Field>
              <ErrorMessage name="project" />
              <div className="row">
                <label className="files" htmlFor="file-upload">
                  Upload Files
                  <img src="/assets/img/Icon.png" alt="" />
                </label>
                <Field
                  id="file-upload"
                  name="files[]"
                  multiple
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files);
                    setFielSelected(event.currentTarget.files.length);
                    // console.log(event.currentTarget.files.length)
                  }}
                  type="file"
                />
                {fielSelected > 0 && <p>{fielSelected} selected files</p>}
              </div>
              <ErrorMessage name="file" />
              <div className="recaptcha">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  //sitekey='6LcYyjQaAAAAAGUiRnoKng7Z77D2I_MzefJ6TPM3'
                  onChange={onChange}
                  hl={"en"}
                />
                <p className={`${errorRecaptcha}`}>Fill the recaptcha</p>
              </div>
              <button type="submit">{txt_submit}</button>
            </Form>
          )}
        </Formik>
      </FormContact>
    </div>
  );
};

export default Contact;
