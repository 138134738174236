import React, { useState, useEffect } from "react";
import FrontPage from "../../Components/Services/FrontPage";
import Filtros from "../../Components/Services/Filtros";
import ConceptService from "../../Components/Services/ConceptService";
import Galery from "../../Components/Services/Galery";
import { portafolio } from "../../utils/portaforio";
import { useLocation, Link } from "react-router-dom";
import RenderingPortafolio from "../../Components/Services/RenderingPortafolio";
import { Helmet } from "react-helmet";
const Rendering = () => {
  let { pathname } = useLocation();
  const [gallery, setGallery] = useState([]);
  const [showDetalle, setShowDetalle] = useState(false);
  const [currentCategory, setCurrenCategory] = useState("all");
  const [showSeparadorDesk, setShowSeparadorDesk] = useState(false);
  const [hideSeparadorTablet, setHideSeparadorTablet] = useState(true);
  const [hideSeparadorMovile, setHideSeparadorMovile] = useState(true);
  const [imageSrc, setImageSrc] = useState(
    "/assets/img/services/Galeria/Rendering/Headers/Rendering_Services-3D_World_rendering_Inc.webp"
  );
  useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth <= 550) {
        setImageSrc(
          "/assets/img/services/Galeria/Rendering/Headers/Rendering_Services-3D_World_rendering_Inc.png"
        );
      } else {
        setImageSrc(
          "/assets/img/services/Galeria/Rendering/Headers/Rendering_Services-3D_World_rendering_Inc.webp"
        );
      }
    };

    window.addEventListener("resize", updateImage);
    updateImage();

    return () => window.removeEventListener("resize", updateImage);
  }, []);
  const filtrosRendering = [
    {
      title: "All",
      link: "/Photorealistic-Rendering-Services",
      portada: imageSrc,
      description: [
        "Our photorealistic rendering can be created from your blueprints, mood boards, or sometimes even from a raw napkin sketch. We help you communicate your design in the most effective way possible.",
        "Perhaps you are pre-selling a new development, filing for a planning permit from your local building department or licensing office, or just looking to charm investors; let us tell your story with powerful creative tools!",
      ],
      separador: true,
    },
    {
      title: "Residential",
      link: "/Residential-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/1.Residential-Header-3D-World-renderings, Inc.webp",
      description: [
        "Make your dream home come to life with our residential rendering services. See your future home as it will eventually be, before construction even begins. Share your vision with your husband, wife, and/or kids, and make your residential project a team effort among the entire family. Give your contractor a clear visual of what you want before the first brick is laid.",
        "Having a computer-generated visualization of your residential plan through our rendering gives you the chance to address any issues that your project may have, saving you the headache of having to deal with them once construction is complete. Let us help you perfect your dream home!",
      ],
      separador: true,
    },
    {
      title: "Brownstone",
      link: "/Brownstone-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/2.Brownstone-Header-3D-World-renderings, Inc.webp",
      description: [
        "Our brownstone & townhouse rendering services allow homeowners to maintain the beauty of these historic buildings while upgrading their interiors to make them more livable today. Our renders give you the ability to modify the interior design to your liking and to explore all of the possibilities that exist when it comes to remodeling your townhouse or brownstone.",
        "Whether the rendering is for your own home, for a client you are trying to sell a townhouse or brownstone to, or an investment project trying to grab investor attention - we’ve got you covered.",
      ],
      separador: hideSeparadorMovile,
    },
    {
      title: "Apartments & Condos",
      link: "/Apartments&Condo-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/2A.Condo-&-Apartments-Header-3D-World-renderings, Inc.webp",
      description: [
        "Have you recently signed a lease on a new apartment or purchased your very own condo? Imagine having a previsualization of the space that would allow you to plan your new residence’s layout accordingly before you even move. We can help you with that.",
        "Real estate developers, sure you’re already aware of how invaluable a tool a rendering of your finished development is. Whether it be for selling purposes or to gather funding, we can help you attain your career goals.",
      ],
      separador: hideSeparadorTablet,
    },
    {
      title: "Penthouse",
      link: "/Penthouse-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/3.Penthouse-Header-3D-World-renderings, Inc.webp",
      description: [
        "Elevate your penthouse experience with our advanced rendering service, offering the luxury of visualizing your dream space before construction. Ideal for discerning buyers, interior designers, and real estate developers, our technology simplifies the planning process",
        "As a buyer, you can ensure every detail aligns with your vision, as you would want with any property you purchase. Interior designers find it a powerful tool for client presentations, and real estate developers gain a 21st-century advantage in property sales. Don't wait for 100% project completion; we'll help you impress from day one, transforming your penthouse dreams into reality.",
      ],
      separador: true,
    },
    {
      title: "Cabin",
      link: "/Cabin-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/3A.Cabin-Header-3D-World-renderings, Inc.webp",
      description: [
        "Have you always dreamt of having a cozy cabin outside of the city? Are you a seasoned developer or builder, tasked with designing someone’s dream cabin or second home? Our cabin rendering service is your essential tool. Visualize, make decisions, and plan the layout of your dream cabin before construction begins.",
        "For developers and builders, our expertise since 2012 ensures increased sales percentages. Harness the power of rendering, CGI, and visualization to transform your dream cabin into reality.",
      ],
      separador: !showSeparadorDesk ? showSeparadorDesk : hideSeparadorMovile,
    },
    {
      title: "Kitchen",
      link: "/Kitchen-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/4.Kitchen-Header-3D-World-renderings, Inc.webp",
      description: [
        "Whether you're a homeowner looking to renovate your kitchen but feeling uncertain, or an interior designer seeking to impress clients, our kitchen rendering service is your creative ally. Visualize and design your dream kitchen, drawing inspiration from sources like Pinterest, books, TV shows, and Instagram. Gain the confidence you need to work with a contractor and bring your kitchen vision to life. Designers, partner with us to leave a lasting impact on your prospects and clients, as 'seeing is believing' when it comes to closing deals. Transform your kitchen projects with our strategic rendering expertise.",
      ],
      separador: true,
    },
    {
      title: "Living Room",
      link: "/Living-Room-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/4A.Living-room-Header-3D-World-renderings, Inc.webp",
      description: [
        "Unlock the potential of your living room with our rendering service. Whether you're a homeowner hesitant to remodel, or a design professional looking to impress, we're here to empower your vision. Visualize and design your dream living room, drawing inspiration from Pinterest, books, TV shows, or Instagram. Gain the confidence to create a space that truly reflects your style.",
        "For interior designers and architects, we're your strategic ally, helping you dazzle prospects and clients. Developers, if you're selling exceptional amenities, we've got the support you need. Make your living room dreams come alive with our expert solutions.",
      ],
      separador: true,
    },
    {
      title: "Bedroom",
      link: "/Bedroom-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/4B.Bedroom-Header-3D-World-renderings, Inc.webp",
      description: [
        "Whether you're a homeowner with bedroom remodeling aspirations or a design professional looking to wow clients, our bedroom rendering service is your answer. Visualize and design your dream bedroom, drawing inspiration from Pinterest, books, TV shows, and Instagram. Gain the confidence to work with a contractor and transform your bedroom into a dream space.",
        "For interior designers, architects, and developers, we're your strategic ally, ready to impress both prospects and clients. Research shows that seeing is believing, so let us help you seal the deal. With us, your bedroom dreams can become a reality.",
      ],
      separador: hideSeparadorTablet,
    },
    {
      title: "Bathroom",
      link: "/Bathroom-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/5.Bathroom-Header-3D-World-renderings, Inc.webp",
      description: [
        "If you're ready to revamp your bathroom but need a little push, our bathroom rendering service is the key. Visualize and design your dream bathroom, drawing inspiration from Pinterest, books, TV shows, and Instagram. Gain the confidence to work with a contractor and create a magazine-worthy dream bathroom.",
        "For interior designers, architects, or developers, we're your strategic ally to impress prospects and clients. As they say, 'Seeing is believing,' and we're here to make sure you leave a lasting impression. Transform your bathroom aspirations into reality with our expertise.",
      ],
      separador: hideSeparadorMovile,
    },
    {
      title: "Amenities",
      link: "/Amenities-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/5A.Amenities-Header-3D-World-renderings, Inc.webp",
      description: [
        "For interior designers, architects, and real estate developers, our amenity rendering service offers a powerful tool to pre-visualize your project before it's even completed. Captivate your clients, investors, and future tenants with a glimpse of the stunning developments that await.",
        "With a portfolio of over 400 real estate projects in the city, our expertise can turn your vision into a compelling reality. As they say, 'A picture is worth a thousand words.' Let us help you create a captivating narrative for your developments.",
      ],
      separador: true,
    },
    {
      title: "Roof Garden",
      link: "/Roof-Garden-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/5B.Roof-Garden-Header-3D-World-renderings, Inc.webp",
      description: [
        "For interior designers, architects, real estate developers, owners, and landscape enthusiasts, our rooftop garden rendering service provides a crucial tool to pre-visualize your rooftop garden's potential before it's realized. Captivate your clients, investors, and prospective tenants with the allure of lush greenery, stunning views, and poolside oasis. ",
        "With a track record of over 400 real estate projects in the city, our expertise transforms your vision into a compelling reality. As the saying goes, 'A picture is worth a thousand words.' Let us help you create an irresistible vision for your rooftop garden developments.",
      ],
      separador: showSeparadorDesk,
    },
    {
      title: "Hospitality",
      link: "/Hospitality-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/6.Hospitality-Header-3D-World-renderings, Inc.webp",
      description: [
        "Whether you're the head of marketing at a hotel, envisioning fresh room designs and pool areas, a bar owner planning a stunning renovation, or an architect or designer working on a project, our hospitality rendering service has you covered. ",
        "Visualize your vision with us, bringing your designs to life and captivating your clients and stakeholders. With our expertise, you can turn your hospitality venture into a breathtaking reality, aligning your goals and impressing your audience with the power of visualization.",
      ],
      separador: !hideSeparadorTablet
        ? hideSeparadorTablet
        : hideSeparadorMovile,
    },
    {
      title: "Restaurant",
      link: "/Restaurant-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/6B.Restaurant-Header-3D-World-renderings, Inc.webp",
      description: [
        "Whether you're a restaurateur dreaming of your next culinary venture or an architecture and interior design firm working on a new restaurant project, we've got you covered. Our roof garden rendering service turns visions into reality, providing valuable insights for design and technical decisions.",
        "From fitting industrial stoves to selecting the right materials, we're your go-to tool for precise planning. Unleash your creative potential, transform your ideas into compelling visuals, and ensure the success of your restaurant projects. With our support, you can make informed choices and captivate your audience.",
      ],
      separador: true,
    },
    {
      title: "Commercial Space",
      link: "/Commercial-Space-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/6C.Commercial- Space-Header-3D-World-renderings, Inc.webp",
      description: [
        "For owners of commercial space, we offer a powerful marketing tool to maximize your property's appeal and value. Utilizing advanced rendering and branding techniques, we transform your office buildings, warehouses, and other assets into compelling marketing materials.",
        "Create brochures, booklets, and presentations to showcase every detail of your property for potential buyers, investors, and financiers. Highlight strategic locations, proximity to key amenities like airports, and the unique selling points of your commercial properties. Let us help you boost your profits and make a convincing case to stakeholders, ensuring your property stands out in the market.",
      ],
      separador: true,
    },
    {
      title: "Mixed Use",
      link: "/Mixed-Use-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/6D.Mixed-Use-Header-3D-World-renderings, Inc.webp",
      description: [
        "Whether you're a property investor, real estate developer, or aspiring tenant, our mixed-use property rendering service adds tremendous value to your vision. We create compelling marketing pitches for investors, unveiling the untapped potential of your project. Visualize the project before it's completed, showcasing its features and benefits to secure your success.",
        "With our expertise, you can transform your concepts into winning realities, impress investors, and drive your career or investment goals forward. Take a step closer to unlocking the full potential of your mixed-use properties through the power of pre-visualization.",
      ],
      separador: hideSeparadorMovile,
    },
    {
      title: "Retail",
      link: "/Retail-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/7.Retail-Header-3D-World-renderings, Inc.webp",
      description: [
        "Whether you're a retail business owner, a brand manager, or part of a corporate team, our retail property rendering service is the key to your success. Picture the future of your retail space with stunning previsualizations. Just like we've created renders for renowned brands like Tory Burch and Victoria's Secret, we can help you bring your vision to life.",
        "Visualize your expansion, gain approval from stakeholders, and secure that green light for your projects. Our rendering service is a powerful tool for achieving your retail goals and ensuring your brand's success. Let us transform your ideas into compelling, vivid realities.",
      ],
      separador: hideSeparadorTablet,
    },
    {
      title: "Office Space",
      link: "/Office-Space-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/8.Office-Space- -Header-3D-World-renderings, Inc.webp",
      description: [
        "If you're the owner of an empty office space, our office property rendering service can help you unlock its full potential. We can transform the blank canvas into a functional, visually stunning office environment, giving you the means to showcase its limitless possibilities to potential tenants or investors.",
        "Our experience extends to creating spaces for pitches, expanding real offices, or reconfiguring them to different sizes. We've partnered with developers worldwide, from New York and Canada to Saudi Arabia, to craft offices of all sizes, styles, and flavors. Let us help you bring your office space vision to life and persuade stakeholders effectively.",
      ],
      separador: showSeparadorDesk,
    },
    {
      title: "Real Estate",
      link: "/Real-Estate-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/9.Real-Estate-Development-Header-3D-World-renderings, Inc.webp",
      description: [
        "At the core of real estate development lies the art of transforming ideas into tangible structures. It starts with discovering the perfect piece of land, defining what it should become, and orchestrating every facet needed for its realization – from financing to architects, contractors, and marketing. Real estate developers, the visionary entrepreneurs of this field, navigate financial risks in the pursuit of creating properties that surpass costs.",
        "Our service empowers developers by providing powerful pre-visualization tools. We collaborate early in the project's life cycle, helping secure funding and instilling confidence in investors. Join us to unlock the potential of your properties, fueling success from concept to completion.",
      ],
      separador: hideSeparadorMovile,
    },
    {
      title: "DOB Signage",
      link: "/DOB-Signage-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/10.DOB-Signage-Header-3D-World-renderings, Inc.webp",
      description: [
        "In the world of construction and renovation in New York, compliance with DOB requirements is essential. When you embark on a building project, displaying the right signage isn't just a choice; it's the law.",
        "Our team specializes in creating the visual representations that will adorn your construction site signage, ensuring full compliance with the city's regulations. We take the guesswork out of this process, making it easy for you to submit your project with confidence. Let us help you navigate the DOB requirements seamlessly and professionally.",
      ],
      separador: true,
    },
    {
      title: "Aerial",
      link: "/Aerial-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/11.Aerial-Header-3D-World-renderings, Inc.webp",
      description: [
        "Experience the power of our aerial rendering service. These images capture your projects from a unique vantage point, offering a bird's-eye view that's perfect for showcasing large-scale developments. Whether you're a real estate developer, urban planner, or city strategist, our aerial visuals provide a vital tool for securing investments, conveying your vision, and educational purposes.",
        "Just as our detailed apartment and condominium renderings assist individuals in envisioning their future homes, our aerial views help professionals in realizing their grand-scale projects. Elevate your project presentations and make a lasting impression with our stunning aerial renderings.",
      ],
      separador: hideSeparadorTablet,
    },
    {
      title: "Event",
      link: "/Event-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/11B.Events-Header-3D-World-renderings, Inc.webp",
      description: [
        "Our event rendering service is the ultimate tool for event planners, wedding organizers, and festival coordinators. From pop-ups and concerts to weddings and fashion shows, we help you pre-visualize every aspect of your event.",
        "See where everything will be located and how it will appear, all before the actual setup. Our visual aids are indispensable for both showcasing your vision and convincing others of your event proposals. Just as we empower apartment and condominium seekers to envision their new homes, we empower event organizers to bring their creative visions to life with precision and clarity.",
      ],
      separador: true,
    },
    {
      title: "School",
      link: "/School-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/11C.School-Header-3D-World-renderings, Inc.webp",
      description: [
        "Our school rendering service is tailored to the educational sector, including kindergartens, primary schools, high schools, universities, and more. Whether you're planning a small school or a massive university campus, we've got you covered.",
        "Just provide us with your blueprints and references, and we can create captivating visual renderings that help you envision, plan, and sell your educational projects. Our powerful tool is not only perfect for educational professionals but also an essential asset to inspire investors and stakeholders by showcasing your vision in a stunning and compelling way.",
      ],
      separador: hideSeparadorMovile,
    },
    {
      title: "Car Dealership",
      link: "/Car-Dealership-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/11D.Car-dealership-Header-3D-World-renderings, Inc.webp",
      description: [
        "If you're in the automotive business, frequent modifications and improvements to your premises are common. Our car dealership rendering service helps you visualize these changes before construction, gaining approvals from boards and investors. We ensure that the proposed modifications are both functional and beautifully designed. ",
        "Are you a marketing director or architect for renowned brands like BMW or Mercedes, looking to envision your new dealership? We're your studio. Our rendering service is a powerful visual marketing tool, providing a clear glimpse of your future dealership's appearance and functionality. Make confident decisions about your automotive space with our assistance.",
      ],
      separador: true,
    },
    {
      title: "Metaverse",
      link: "/Metaverse-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/11E.Metaverse-Header-3D-World-renderings, Inc.webp",
      description: [
        "We've ventured into various projects within the Metaverse, delivering photorealistic end-to-end client services in the emerging web3/metaverse. Our expertise extends to real estate, hospitality, and eCommerce, providing you with a futuristic vision of your digital presence.",
        "Join us in shaping the metaverse of tomorrow, where we create immersive and visually stunning experiences for your brand. Embrace the limitless possibilities of this digital frontier, where we help you stand out in the evolving landscape of the metaverse. Explore, create, and innovate with our metaverse rendering service",
      ],
      separador: showSeparadorDesk,
    },
    {
      title: "Religious",
      link: "/Religious-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/12.Religious-Header-3D-World-renderings, Inc.webp",
      description: [
        "When it comes to places of worship or religious centers, be it a church, synagogue, Buddhist temple, or mosque among others, we bring extensive experience to the table. No matter your project’s religious origin, we take pride in assisting you in bringing your religious structures to life.",
        "Visualize your sacred concepts with our dedicated rendering service, where craftsmanship meets spirituality. Explore our portfolio to witness the diversity and excellence we’ve achieved in rendering various religious edifices over the years.",
      ],
      separador: !hideSeparadorTablet
        ? hideSeparadorTablet
        : hideSeparadorMovile,
    },
    {
      title: "Product Design",
      link: "/Product-Design-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/13.Product-Design-Header-3D-World-renderings, Inc.webp",
      description: [
        "Imagine having the power to visualize your innovative product, even before it's fully developed, to attract the funding and attention it deserves. We specialize in product design rendering services that turn concepts into compelling visual realities, just like the success story of Mike Cessario, who enlisted our help for his future product at Liquid Death.",
        "Our renders played a pivotal role, allowing him to pitch the product effectively and secure deals even before it was fully ready. Today, his company is valued at $700 million USD. With the magic of our rendering, you can create compelling product catalogs and turn your inventive ideas into reality and success.",
      ],
      separador: true,
    },
    {
      title: "Axonometric",
      link: "/Axonometric-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/14.Axonometric-Header-3D-World-renderings, Inc.webp",
      description: [
        "Axonometric projection, a remarkable rendering service, employs a distinctive method to create pictorial representations of objects. It involves rotating an object around one or more axes, revealing various sides in a comprehensible manner. This technique proves invaluable, especially for real estate professionals.",
        "Unlike conventional 2D floor plans that may pose challenges for some, axonometric projections provide a user-friendly approach to understanding complex spatial designs. It's like slicing through a building with an imaginary knife, allowing you to explore the interior layout with ease. Take a closer look at our portfolio of axonometric renders to gain a deeper appreciation of this exceptional tool.",
      ],
      separador: true,
    },
    {
      title: "Hand Drawn",
      link: "/Hand-Drawn-Rendering-Services",
      portada:
        "/assets/img/services/Galeria/Rendering/Headers/15.Hand-Drawn-Header-3D-World-renderings, Inc.webp",
      description: [
        "Our hand-drawn renders offer a more budget-friendly option compared to computer-generated or CGI renders. They are perfect when you need to manage costs more effectively. In some cases, they can also be quicker to produce.",
        "These versatile renders can encompass everything from exterior to interior designs, available in various sizes and formats. Whether you prefer monochromatic sketches or colorful representations, you can count on us.",
      ],
      separador: false,
    },
  ];
  const filtro = filtrosRendering.filter((filtro) => filtro.link == pathname);
  const handleSetGalleryItems = () => {
    const selectedPortfolio =
      portafolio.find((filtro) => filtro.path == pathname) ?? {};
    const galleryItems = selectedPortfolio?.items ?? [];
    setCurrenCategory(selectedPortfolio.category);
    setGallery(galleryItems);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    handleSetGalleryItems();
    setShowDetalle(false);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSetGalleryItems();
    if (window.innerWidth <= 1450) {
      setShowSeparadorDesk(false);
      setHideSeparadorTablet(true);
      setHideSeparadorMovile(true);
    }
    if (window.innerWidth <= 820) {
      setShowSeparadorDesk(true);
      setHideSeparadorTablet(false);
      setHideSeparadorMovile(true);
    }
    if (window.innerWidth <= 430) {
      setHideSeparadorMovile(false);
      setShowSeparadorDesk(true);
      setHideSeparadorTablet(true);
    }
    const resize = () => {
      if (window.innerWidth >= 1024) {
        setShowSeparadorDesk(false);
        setHideSeparadorTablet(true);
        setHideSeparadorMovile(true);
      }
      if (window.innerWidth <= 820) {
        setShowSeparadorDesk(true);
        setHideSeparadorTablet(false);
        setHideSeparadorMovile(true);
      }
      if (window.innerWidth <= 430) {
        setHideSeparadorMovile(false);
        setShowSeparadorDesk(true);
        setHideSeparadorTablet(true);
      }
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  const handlePortafolio = (id, show) => {
    const vista = portafolio.find((filtro) => filtro.path == pathname);
    const clicked = vista.items.find((row) => row.id == id);
    // Convert array to set to remove duplicates
    let uniqueSet = new Set([clicked, ...vista.items].map(JSON.stringify));
    // Convert set back to array
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
    setGallery(uniqueArray);
    setShowDetalle(show);
    if (window.screen.width < 560) {
      window.scrollTo(0, 800);
    } else {
      window.scrollTo(0, 1500);
    }
  };
  const renderConceptServicesContent = () => {
    return (
      <ConceptService>
        <div className="title">
          {filtro.map((value, index) => (
            <h2 key={index}>
              Our {value.title == "All" ? "Photorealistic" : value.title}
            </h2>
          ))}
          <h3>Rendering</h3>
        </div>
        {filtro[0].description.map((value, index) => (
          <p key={index} className="rendering">
            {value}
          </p>
        ))}
      </ConceptService>
    );
  };
  return (
    <>
      <div className="body-content">
        <Helmet>
          <title>Rendering | 3D World renderings, Inc.</title>
          <meta
            name="description"
            content="Bring your design to life with our photorealistic renderings. From blueprints to napkin sketches, we communicate your vision effectively. Perfect for pre-selling, permits, and captivating investors. Let us tell your story with powerful creative tools."
          />
        </Helmet>
        <FrontPage
          alt={"Rendering Services - 3D World renderings, Inc"}
          portada={filtro[0].portada}
          name={"Rendering"}
          subtitule={"Services"}
        >
          {renderConceptServicesContent()}
        </FrontPage>
        {renderConceptServicesContent()}
        <Filtros>
          <div className="filters-container">
            <ul>
              {filtrosRendering.map((filter, index) => (
                <React.Fragment key={index}>
                  <li
                    {...(pathname === filter.link && {
                      className: "active",
                    })}
                  >
                    <Link to={filter.link}>{filter.title}</Link>
                  </li>
                  {filter.separador && <div></div>}
                </React.Fragment>
              ))}
            </ul>
          </div>
        </Filtros>
      </div>
      {!showDetalle && (
        <Galery
          id={currentCategory}
          gallery={gallery}
          handlePortafolio={handlePortafolio}
        ></Galery>
      )}
      {showDetalle && (
        <RenderingPortafolio gallery={gallery}></RenderingPortafolio>
      )}
    </>
  );
};

export default Rendering;
