import React, { useState, useEffect } from "react";
import FrontPage from "../../Components/Services/FrontPage";
import Filtros from "../../Components/Services/Filtros";
import ConceptService from "../../Components/Services/ConceptService";
import Galeryv2 from "../../Components/Services/Galeryv2";
import { galeriesVirtual } from "../../utils/portaforio";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const VirtualReality = () => {
  const [gallery, setGallery] = useState(galeriesVirtual);
  let { pathname } = useLocation();

  const filtrosVR = [
    {
      title: "All",
      link: "/Virtual-Reality-Services",
    },
    {
      title: "First Class",
      link: "/Virtual-Reality-First-Class",
    },
    {
      title: "Business",
      link: "/Virtual-Reality-Business",
    },
    {
      title: "Economy",
      link: "/Virtual-Reality-Economy",
    },
  ];
  const filtro = filtrosVR.filter((filtro) => filtro.link == pathname);

  useEffect(() => {
    if (pathname == "/Virtual-Reality-Services") {
      setGallery(galeriesVirtual);
    } else {
      setGallery(
        galeriesVirtual.filter((filtro) => filtro.category == pathname)
      );
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderConceptServicesContent = () => {
    return (
      <ConceptService>
        <div className="title">
          {filtro.map((value, index) => {
            return (
              <>
                {value.link == "/Virtual-Reality-Services" && (
                  <h2>Our First Class</h2>
                )}
                {value.link !== "/Virtual-Reality-Services" && (
                  <h2>Our {value.title}</h2>
                )}
              </>
            );
          })}
          <h3>Virtual Reality</h3>
        </div>
        <p className="virtualR">
          Our interactive Virtual Reality experience offering is ideal for
          organizing and creating highly-specialized marketing campaigns for the
          real estate industry all in one platform. This is an immersive prime
          real estate marketing tool that allows the buyer to feel what it’s
          like to be in an unbuilt property. By integrating the marketing and
          sales experiences, we are able to open a dialogue for cross-functional
          teamwork, and by doing so we have sharpened the user experience
          between the development, leasing and marketing teams. Interactive VR
          applications are compatible with smartphones, desktops and tablets.
        </p>
      </ConceptService>
    );
  };
  return (
    <div className="body-content">
      <Helmet>
        <title>Virtual Reality | 3D World renderings, Inc.</title>
        <meta
          name="description"
          content="Immerse buyers in unbuilt properties with our interactive VR experience. A prime real estate marketing tool that integrates marketing, sales, and cross-functional teamwork. Compatible with smartphones, desktops, and tablets."
        />
      </Helmet>
      <FrontPage
        alt={"Virtual Reality Service - 3D World renderings, Inc."}
        column={"flex-column"}
        portada={
          "/assets/img/services/Galeria/VirtualR/Virtual_Reality_Services-3D_World_renderings_Inc.jpg"
        }
        name={"Virtual Reality"}
        subtitule={"Services"}
      >
        {renderConceptServicesContent()}
      </FrontPage>
      {renderConceptServicesContent()}
      <Filtros>
        <div className="filters-container">
          <ul>
            {filtrosVR.map((val, index) => {
              return (
                <>
                  {pathname == val.link && (
                    <>
                      <li key={index} className="active">
                        <Link to={val.link}>{val.title}</Link>
                      </li>
                      <div></div>
                    </>
                  )}
                  {pathname != val.link && (
                    <>
                      <li key={index}>
                        <Link to={val.link}>{val.title}</Link>
                      </li>
                      <div></div>
                    </>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </Filtros>
      <Galeryv2 type="VirtualReality" gallery={gallery}></Galeryv2>
    </div>
  );
};

export default VirtualReality;
