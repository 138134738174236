import React, { useEffect, useState } from "react";
import Slider from "../Components/Slider";
import { SwiperSlide } from "swiper/react";
import SliderComun from "../Components/SliderComun";
import { CSSTransition } from "react-transition-group";
import VideoPlayer from "../Components/VideoPlayer";
import YouTube from "react-youtube";
import { Link } from "react-router-dom";
import useDocumentTitle from "../Components/UseDocumentTitle";
import {
  Button,
  ContentPortada,
  ContentConcepto,
  ContentServices,
  Video,
  ContentProcess,
  ContentStudio,
  ContentServDetail,
  ContentClientes,
  ContentClientsSay,
  ContentPhilosophy,
  ContentTeam,
  ContentQuestions,
} from "../Styles/Styles";
import {
  ButtonLink,
  OurExperienceCarouselContainer,
  ContentPhilosophyMobile,
} from "../Styles/StyledHome";
import { FaqList } from "../Components/FaqList/FaqList";
import { WhatWeDo as WhatWeDoMobile } from "../Components/WhatWeDo/WhatWeDo";
const Home = (props) => {
  const [typeServ, setTypeServ] = useState("renderings");
  const [currentPositionX, setCurrentPositionX] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  const [logoMobile, setLogoMobile] = useState(
    "/assets/img/Clients_&_Collaborators-3D_World_renderings_Inc.jpg"
  );
  const [width, setWidth] = useState(window.innerWidth);
  const [heigth, setHeight] = useState(window.innerHeight);
  useDocumentTitle("NYC Creative Studio | 3D World renderings, Inc.");
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      mute: 1,
      rel: 0,
    },
  };
  const handleServicios = (type) => {
    setTypeServ(type);
    let serv = document.getElementById(type);
    // console.log(serv)
    let activo = document.getElementsByClassName("active");
    activo[0].classList.remove("active");
    serv.classList.add("active");
  };
  const handleAnswer = (number) => {
    setShowAnswer(true);
    let num = document.getElementById(number);
    let activo = document.getElementsByClassName("show");
    //console.log(activo)
    if (activo.length > 0) {
      activo[0].classList.remove("show");
    }

    num.classList.add("show");
  };
  const transform = document.getElementById("slider");

  function onMouseMove(event) {
    // console.log('onMouseMoveEvent.clientX: ', event.clientX)
    //console.log('onMouseDownEvent: ', currentPositionX)
    transform.style.transform = "translateX(-" + event.clientX + "px)";
  }

  const handleSlider = (e) => {
    // console.log(e)
    setCurrentPositionX(e.clientX);
    transform.addEventListener("mousemove", onMouseMove);
  };

  const onMouseUp = () => {
    // console.log('OnmouseUp')
    transform.removeEventListener("mousemove", onMouseMove);
  };

  const handleVideo = (data) => {
    if (data.data === 0) {
      setOpen(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth > 1300) {
      setWidth(window.innerWidth - 50);
    } else {
      setWidth(1858);
    }
    if (window.innerWidth <= 1024) {
      setMobile(true);
      setLogoMobile(
        "/assets/img/Clients_&_Collaborators_mobile-3D_World_renderings_Inc_mobile.jpg"
      );
      setWidth(1880);
    }
    const handleResize = () => {
      if (window.innerWidth > 1300) {
        setWidth(window.innerWidth - 50);
      } else {
        setWidth(1858);
      }
      // setHeight(this.window.innerHeight)
      if (window.innerWidth <= 1024) {
        setMobile(true);
        setLogoMobile(
          "/assets/img/Clients_&_Collaborators_mobile-3D_World_renderings_Inc_mobile.jpg"
        );
        setWidth(1880);
      } else {
        setMobile(false);
        setLogoMobile(
          "/assets/img/Clients_&_Collaborators-3D_World_renderings_Inc.jpg"
        );
      }
      setMobile(window.innerWidth <= 550);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="body-content">
      <ContentPortada>
        {/* <VideoBackground></VideoBackground> */}
        {/* <video autoPlay loop muted src="/assets/portadaHome.mp4"></video> */}
        {mobile ? (
          <img
            src="/assets/Home_movil.jpg"
            alt="Imagen portada home"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <VideoPlayer
            URL={"https://vimeo.com/823220522"}
            VIDEO_WIDTH={width}
            VIDEO_HEIGHT={1060}
            mute={true}
          ></VideoPlayer>
        )}
        <div className="background-black"></div>
        <div className="slogan">
          <h2>
            Visual Storytelling for Real Estate Development, Architecture and
            Design
          </h2>
          <Button opacity="true" weight size="true" font portada>
            <Link to="/Contact">Contact Us</Link>
          </Button>
        </div>
      </ContentPortada>
      <ContentConcepto id="home-services">
        <div className="left">
          <SliderComun pagination={false}>
            <SwiperSlide>
              <div className="referencia_concept">
                <div className="top">
                  <p>
                    “It has been a pleasure working with 3D World; they are
                    creative, collaborative, and truly captured the essence of
                    our team’s vision for this groundbreaking project with their
                    exceptional renderings and animation. We are beyond pleased
                    with the results.”{" "}
                  </p>
                  <span>Robert V. - Developer</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="referencia_concept">
                <div className="top">
                  <p>
                    “As a contractor I’m always bidding against other
                    contractors for a job, but ever since I’ve made 3D World my
                    go-to for render, I no longer have to worry. I always come
                    to the client with a stunning rendering that shows the
                    project in its completion - that extra 10% always lands me
                    the job.”
                  </p>
                  <span>Frank G. - Contractor</span>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="referencia_concept">
                <div className="top">
                  <p>
                    “My husband and I bought an older house for a great price;
                    remodeling would give us a chance to truly make it our own.
                    We asked 3D World to materialize our vision through an
                    interactive virtual space, a great tool for us to make
                    decisions that also served as a guideline for our
                    contractors. Thanks to 3DW, today we’re living in the dream
                    home we always envisioned.”
                  </p>
                  <span>Melissa & Andrew S. - Homeowners</span>
                </div>
              </div>
            </SwiperSlide>
          </SliderComun>
          <Button absolute padding subrayado>
            <Link target="_blank" to="https://calendly.com/3dworld/15minutes ">
              Schedule a Call
            </Link>
          </Button>
          {/* <div className='subrayado'></div> */}
        </div>
        {mobile && (
          <div className="right">
            <div className="top">
              <h1>
                <b>A New York City creative studio</b>{" "}
                <span>
                  specializing in 3D rendering, branding, and marketing
                  campaigns.
                </span>
              </h1>
            </div>
            <p>
              We create stunning CGI, branding & marketing material for
              developers, architects, and advertising agencies.
            </p>
            <div className="linea"></div>
            <div>
              <ButtonLink>
                <Link to="https://calendly.com/3dworld/15minutes">
                  Schedule a Call
                </Link>
              </ButtonLink>
            </div>
            {/* <div className='subrayado mobile'></div> */}
          </div>
        )}
        {!mobile && (
          <div className="right">
            <div className="top">
              <h1>
                <b>A New York City creative studio</b>{" "}
                <span>
                  specializing in 3D rendering, branding, and marketing
                  campaigns.
                </span>
              </h1>
            </div>
            <p>
              We create stunning CGI, branding & marketing material for
              developers, architects, and advertising agencies.
            </p>
          </div>
        )}
      </ContentConcepto>
      {mobile ? (
        <WhatWeDoMobile />
      ) : (
        <ContentServices>
          {
            typeServ === "renderings" && (
              <VideoPlayer
                URL={"https://vimeo.com/823224982"}
                VIDEO_WIDTH={1920}
                VIDEO_HEIGHT={1060}
                mute={true}
              ></VideoPlayer>
            )
            // <video preload='auto' muted autoPlay loop src="/assets/img/Rendering.mp4" alt="Renderinc in New York"></video>
          }
          {
            typeServ === "animations" && (
              <VideoPlayer
                URL={"https://vimeo.com/823227132"}
                VIDEO_WIDTH={1920}
                VIDEO_HEIGHT={1060}
                mute={true}
              ></VideoPlayer>
            )
            // <video alt="Animation Services in NYC" preload='auto' src="/assets/img/Animation.m4v" muted autoPlay loop></video>
          }
          {
            typeServ === "virtuals" && (
              <VideoPlayer
                URL={"https://vimeo.com/823231603"}
                VIDEO_WIDTH={1920}
                VIDEO_HEIGHT={1060}
                mute={true}
              ></VideoPlayer>
            )
            // <video preload='auto' src="/assets/img/Virtual.m4v" muted autoPlay loop></video>
          }
          {typeServ === "interactives" && (
            <VideoPlayer
              URL={"https://vimeo.com/881001520"}
              VIDEO_WIDTH={1920}
              VIDEO_HEIGHT={"100%"}
              mute={true}
            ></VideoPlayer>
          )}
          {typeServ === "brandings" && (
            <video
              preload="auto"
              src="/assets/img/branding.m4v"
              muted
              autoPlay
              loop
            ></video>
          )}
          {typeServ === "vfxs" && (
            <video
              preload="auto"
              src="/assets/img/servicio_vfx.mp4"
              muted
              autoPlay
              loop
            ></video>
          )}
          <div className="background-black"></div>
          <div className="texto">
            <h2>What We Do</h2>
            <p>
              We make experiencing <br /> your ideas possible.
            </p>
          </div>
          <div className="servicios">
            <Link to={"/Photorealistic-Rendering-Services"}>
              <h3
                id="renderings"
                className="active"
                onMouseEnter={() => handleServicios("renderings")}
              >
                Rendering
              </h3>
            </Link>
            <Link to={"/Animation-Services"}>
              <h3
                id="animations"
                onMouseEnter={() => handleServicios("animations")}
              >
                Animation
              </h3>
            </Link>
            <Link to={"/Virtual-Reality-Services"}>
              <h3
                id="virtuals"
                onMouseEnter={() => handleServicios("virtuals")}
              >
                Virtual Reality
              </h3>
            </Link>
            <Link to={"/Interactive-Services"}>
              <h3
                id="interactives"
                onMouseEnter={() => handleServicios("interactives")}
              >
                Interactive
              </h3>
            </Link>
            <Link to={"/Branding-Services"}>
              <h3
                id="brandings"
                onMouseEnter={() => handleServicios("brandings")}
              >
                Branding
              </h3>
            </Link>
            <Link to={"/VFX-Services"}>
              <h3 id="vfxs" onMouseEnter={() => handleServicios("vfxs")}>
                VFX
              </h3>
            </Link>
          </div>
        </ContentServices>
      )}

      <ContentStudio>
        <h2>The Studio</h2>
        <div className="studio">
          <p>
            Located at 33 Irving Place, 3D World provides highly personalized,
            face-to-face services to our NYC clients, as well as virtual
            consultations to our clientele reaching all corners of the map.
            Whatever the nature of your project might be, rest assured you will
            find extraordinary value in envisioning your ideas to life! The 3DW
            family includes skilled groups of architects, designers, experienced
            3D | CGI artists, technologists, and project managers. For over a
            decade, we at 3DW have been mastering the art of curating
            collaborative teams to aid our clients in projects of all sizes.
            Each carefully selected team enhances the design process with
            collaboration and feedback from you until a project is completed. If
            you’re a real estate developer, architect, interior designer,
            contractor, homeowner, event planner, branding and/or marketing
            agency looking to take your conceptual idea to the next level, we
            can help your business reach its goals.
          </p>
        </div>
      </ContentStudio>
      <Video>
        <div className="video">
          <a onClick={() => setOpen(true)}>
            <h2>Watch Our Reel</h2>
            <img src="/assets/img/img-Youtube2.jpg" alt="" />
          </a>
          <CSSTransition
            in={isOpen}
            timeout={200}
            classNames="show"
            unmountOnExit
          >
            <YouTube
              videoId="4t7atu0Ciww"
              opts={opts}
              onStateChange={handleVideo}
            />
            {/* <iframe src="https://www.youtube.com/embed/4t7atu0Ciww?autoplay=1&controls=0&mute=1&loop=1&rel=0&playlist=4t7atu0Ciww&modestbranding=1&showinfo=0&enablejsapi=1&&widgetid=3" frameborder="0"></iframe> */}
          </CSSTransition>
        </div>
      </Video>
      <ContentProcess>
        <div className="top">
          <div className="expertise-card">
            <h2>Our Expertise</h2>
            <div className="process">
              <p>
                We are experts in the creation of successful campaigns for the
                residential, commercial, hospitality, destination, cultural and
                creative sectors having contributed to them since 2012. As a
                boutique studio of interdisciplinary creatives, architects and
                designers, our goal is to combine visual disciplines to envision
                your ideas one step closer to reality.
              </p>
            </div>
          </div>
          <div className="process-card">
            <h2>Our Process</h2>
            <div className="process">
              <p>
                Through our unique process, we first focus on attaining a deep
                understanding of our clients’ needs so that we can effectively
                interpret their ideas and concepts. We strive to grasp and
                convey client goals productively through a mixture of visual
                disciplines and skills including Rendering, Animation, Virtual
                Reality, Interactive, Branding, VFX and more.
              </p>
            </div>
          </div>
        </div>
        {/* The Slider Component will only be visible in mobile */}
        <OurExperienceCarouselContainer>
          <Slider />
        </OurExperienceCarouselContainer>
        {/* This tag slider will only be visible in desktop */}
        <div className="slider">
          <div className="content-process">
            <h3>We Listen</h3>
            <p>
              At our first meeting, your ideas, needs, goals and expectations
              for your project take center stage. Our objective is to achieve a
              thorough understanding of the work at hand so that we can find the
              solutions you seek. From the very beginning, we establish a
              culture of open communication and confidence – the more you share,
              the better the result. Transparency and collaboration are key. We
              work within the constraints of your reality and budget. Provide us
              with a range you are willing to commit to and we will make sure
              that you get the most out of your investment. We then provide you
              with a hand-picked team from our many experienced project
              managers, brand strategists, copywriters, 3D artists, CGI artists,
              graphic designers and technologists. Our specialized team of
              creatives will stick with you, through thick and thin, to the
              completion of your project.
            </p>
          </div>
          <div className="content-process">
            <h3>We Create</h3>
            <p>
              The 3DW approach is very hands-on. From the tailoring of each
              client’s specialized team to the constant self-evaluation of our
              work and services, we do everything in our power to ensure that
              our output is in line with our high-quality standards. Our
              directors work personally with all clients at some point during
              the execution phase and expertly curate delivery packages with the
              aim of enabling customer success. No work leaves our studio
              without our founder’s careful inspection, approval and finishing
              touches. We pride ourselves on our comprehensive understanding of
              the market, attention to detail, and on the highly personalized
              service we extend to all of our clients. In today’s digital age,
              we understand the importance of flexibility. That is why we’re
              always available for a face-to-face meeting, video or phone call.
            </p>
          </div>
          <div className="content-process">
            <h3>We Deliver</h3>
            <p>
              Good design takes time. We’ve tried to do it the fast and easy way
              - trust us, it doesn’t work. We stick to the timeline we have
              mutually agreed upon and established during the process. We
              maintain open communication throughout the development of your
              project to ensure efficient, quality work. Once we have reached
              the end of the timeline, received and updated all of your feedback
              and made all of the necessary adjustments as per the agreed number
              of revisions, the creation process is complete. 3DW delivers the
              final product to you as a proud design partner and project
              collaborator. We guarantee your full satisfaction with the results
              of our partnership and with our high-quality work, along with an
              incomparable sense of fulfillment; all on the expected date. We
              always deliver on time, every time.
            </p>
          </div>
        </div>
      </ContentProcess>
      <ContentServDetail>
        <h2>Our Services</h2>
        <div className="content-services">
          <p>
            Great storytelling relies on the two-way interaction between who’s
            telling the story and their audience. Their input and reactions
            influence the way the story is told. At our studio, we want to help
            you tell your story in the most effective way possible. That’s why
            we put great effort into fully understanding your vision or core
            idea to create digital, visual, marketing or branding solutions for
            your project or campaign. Whatever the nature of your strategy might
            be, you will find extraordinary value in turning your ideas into
            experiences with us.
          </p>
        </div>
        <div className="services">
          <div className="card">
            <div className="img ">
              <Link to={"/Rendering-Services"}> </Link>
              {isMobile ? (
                <img
                  className="mobile"
                  src="/assets/img/Rendering_movil.jpg"
                  alt="Rendering"
                />
              ) : (
                <VideoPlayer
                  URL={"https://vimeo.com/823224982"}
                  VIDEO_WIDTH={1000}
                  VIDEO_HEIGHT={"100%"}
                  mute={true}
                />
              )}
            </div>
            <div className="text">
              <h3>Rendering</h3>
              <p>
                Our photorealistic rendering can be created from your
                blueprints, mood boards or sometimes even from a raw napkin
                sketch. We help you communicate your design in the most
                effective way possible. Perhaps you are pre-selling a new
                development, filing for a planning permit from your local
                building department or licensing office, or just looking to
                charm investors, let us tell your story with powerful creative
                tools!
              </p>
              <ButtonLink>
                <Link to="/Photorealistic-Rendering-Services">
                  Explore Rendering
                </Link>
              </ButtonLink>
              {/* <div className="subrayado servicios"></div> */}
            </div>
          </div>
          <div className="card reverse">
            <div className="img">
              <Link to="/Animation-Services"> </Link>
              <VideoPlayer
                URL={"https://vimeo.com/823227132"}
                VIDEO_WIDTH={1000}
                VIDEO_HEIGHT={"100%"}
                mute={true}
              ></VideoPlayer>
            </div>
            <div className="text">
              <h3>Animation</h3>
              <p>
                Our animation service will take your project to the next level.
                While renderings are a powerful tool to present your concept
                with, developing visual marketing content is critical in any
                digital strategy. Animations can provide a dynamic fly-through
                of your project, a better understanding of its spatial design,
                or a teaser that will captivate and excite your audience.
              </p>
              <ButtonLink>
                <Link to="/Animation-Services">Explore Animation</Link>
              </ButtonLink>
              {/* <div className='subrayado servicios left'></div> */}
            </div>
          </div>
          <div className="card">
            <div className="img">
              <Link to="/Virtual-Reality-Services"></Link>
              {isMobile ? (
                <img
                  className="mobile"
                  src="/assets/img/Virtual_Reality_movil.jpg"
                  alt="Virtual Reality"
                />
              ) : (
                <VideoPlayer
                  URL={"https://vimeo.com/823231603"}
                  VIDEO_WIDTH={1000}
                  VIDEO_HEIGHT={"100%"}
                  mute={true}
                />
              )}
            </div>
            <div className="text">
              <h3>Virtual Reality</h3>
              <p>
                Our immersive Virtual Reality tours are a prime real estate
                marketing tool that allows the buyer to feel what it’s like to
                be in an unbuilt property. We achieve this through the use of
                highly detailed 360º renderings. A key feature of our VR 360º
                tours is interacting with the innate details of a future
                location or multiple spaces at once. Virtual tours are a
                trending service in the real estate industry, used as a
                standalone product or enhanced with rendering and animations.
              </p>
              <ButtonLink>
                <Link to="/Virtual-Reality-Services">
                  Explore Virtual Reality
                </Link>
              </ButtonLink>
              {/* <div className='subrayado servicios morewidth'></div> */}
            </div>
          </div>
          <div className="card reverse">
            <div className="interactive-card">
              <div className="img">
                <Link to="/Interactive-Services"></Link>
                {isMobile ? (
                  <img
                    className="mobile"
                    src="/assets/img/Interactive_movil.jpg"
                    alt="Interactive"
                  />
                ) : (
                  <VideoPlayer
                    URL={"https://vimeo.com/881001520"}
                    VIDEO_WIDTH={"125%"}
                    VIDEO_HEIGHT={"100%"}
                    mute={true}
                  />
                )}
              </div>
              <div className="text">
                <h3>Interactive</h3>
                <p>
                  Our interactive experience offering is ideal for organizing
                  and creating highly-specialized marketing campaigns for the
                  real estate industry all in one platform. By integrating the
                  marketing and sales experiences, we are able to open a
                  dialogue for cross-functional teamwork, and by doing so we
                  have sharpened the user experience between the development,
                  leasing and marketing teams. Interactive applications are
                  compatible with smartphones, desktops and tablets.
                </p>
                <ButtonLink>
                  <Link to="/Interactive-Services">Explore Interactive</Link>
                </ButtonLink>
                {/* <div className='subrayado servicios left'></div> */}
              </div>
            </div>
          </div>
          <div className="card ">
            <div className="img">
              <Link to="/Branding-Services"></Link>
              {isMobile ? (
                <img
                  className="mobile"
                  src="/assets/img/Branding_movil.jpg"
                  alt="Branding"
                />
              ) : (
                <video
                  preload="auto"
                  src="/assets/img/branding.m4v"
                  autoPlay
                  muted
                  loop
                ></video>
              )}
            </div>
            <div className="text">
              <h3>Branding</h3>
              <p>
                Branding plays an indispensable part in real estate development
                and marketing ventures. That’s why we’ve created our own brand
                development method composed of four frameworks (Business,
                Creative, Narrative, and Graphic) in order to find the best
                strategic approach suited to your goals. Whether you’re
                launching a new real estate development in the city that wants
                to stand out in the crowd or simply in need of a marketing tool
                for a new listing that’s about to go live, we create tailor-made
                brands and strategies for a luxury real estate market that are
                essential in pitching an idea, raising capital, and ensuring
                your project’s development.
              </p>
              <ButtonLink>
                <Link to="/Branding-Services">Explore Branding</Link>
              </ButtonLink>
              {/* <div className='subrayado servicios lesswidth'></div> */}
            </div>
          </div>
          <div className="card reverse">
            <div className="img">
              <Link to="/VFX-Services"></Link>
              {isMobile ? (
                <img
                  className="mobile"
                  src="/assets/img/VFXmovil.jpg"
                  alt="VFX"
                />
              ) : (
                <video
                  preload="auto"
                  src="/assets/img/servicio_vfx.mp4"
                  autoPlay
                  muted
                  loop
                ></video>
              )}
            </div>
            <div className="text">
              <h3>VFX</h3>
              <p>
                Our Visual Effects or VFX service will help you create, modify,
                or enhance visual content by creating 3D models and animations,
                compositing footage, creating special effects, and more. Our
                team will collaborate closely with you to fully understand your
                needs and objectives. With this knowledge, we employ our
                technical know-how and advanced software tools to craft
                top-notch visual content that caters exclusively to your needs.
                Our VFX service will allow you to enhance your project’s visual
                appeal and value whether it be for a commercial, social media
                campaign, real estate presentation, or website - we’ve got you
                covered.{" "}
              </p>
              <ButtonLink>
                <Link to="/VFX-Services">Explore VFX</Link>
              </ButtonLink>
              {/* <div className='subrayado servicios left lesswidth_2'></div> */}
            </div>
          </div>
        </div>
      </ContentServDetail>
      <ContentClientes>
        <h2>Our Clients & Collaborators</h2>
        <img
          src={logoMobile}
          alt="Clients & Collaborators - 3D World renderings, Inc.jpg"
        />
      </ContentClientes>
      <ContentClientsSay>
        <h2>What Our Clients Are Saying</h2>
        <SliderComun pagination={true}>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “The 3D World team turned around a beautiful and high-end
                rendering & animation for our client. They were excellent to
                work with and understood our needs. They also delivered
                everything exactly on schedule. Thank you 3D World. Definitely
                coming back with more projects.”
              </p>
              <p>Todd Broder – PB&I</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “We have been working with 3D World for the last few years and
                really appreciate their hard work and understanding of each of
                our projects. They are able to take our vision for development
                sites and make them a reality. We have had the pleasure of
                working closely with Diego & Alex, who asks great questions and
                communicates our needs to her team, turning around projects
                quickly and efficiently.”
              </p>
              <p>Antonina Agrusa – Terra Comercial Realty Group</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “Five stars is not enough for this team! 10 is more like it,
                they are incredibly responsive, their work is essentially
                perfect, and they will take the time to understand your project
                in order to ensure the most satisfying results. They offer
                support post normal business hours and will edit the tiniest
                details to get you the perfect renderings and animations. I wish
                I knew of them sooner. Use them, you won't regret it, that's a
                promise!”
              </p>
              <p>Aaron Dahan - Caffè Aronne</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “My partner and I are in the middle of a gut renovation of our
                new home. Since it is our first time renovating a house, we
                hired 3D World to help us realize the vision we had in our head.
                They first did a virtual 360° render of our kitchen/living room
                area and the results were amazing! We are so impressed that we
                actually ended up hiring them to do a few more areas of the
                house. It is totally worth it to be able to see how everything
                we wanted would actually fit together on paper. Diego and Alex
                were super attentive and catered to our needs. I am looking to
                have them do a few more renderings of the exterior of our home
                in the near future.”
              </p>
              <p>Kerry Imai – Homeowner</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “I was so desperately in need of some 3D visuals for one of my
                project presentations around the corner, one of my coworkers
                send me the contact of Diego and Ale from 3D World. After
                talking to both of them, I immediately felt they were the
                perfect fit for me. Diego and his architectural visual team
                brought all of my ideas and designs to life, always addressing
                any concerns I had at hand. They are genuinely excellent visual
                storytellers with top-notch rendering services. I would highly
                recommend them if you’re in the New York City area.
              </p>
              <p>William Doster - J. Realtors</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “I am very pleased and feel we got a very good value in our
                project, and it worked out well. The process seems to be similar
                to other firms, of course, but speaking English and being in the
                same time zone and being able to collaborate in real-time was
                very helpful”
              </p>
              <p>Ron Barth - Resource Furniture</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “Diego and his team are amazing. You can't believe that his
                designs aren't a photo of the actual space. I've used them for
                my clients and it's made the difference in closing the deal”
              </p>
              <p>Celia Reiss - Kitchen and Bathroom Designer</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “A very reliable and solid studio. They have great experience
                and do fantastic work in renderings, animations & branding.
                Diego, the studio's director, is always easy to communicate
                with, truly a responsible professional and human being. I would
                recommend 3D World to everyone looking to hire them!”
              </p>
              <p>Jacob Cohen – Alive Studio</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “I run a digital agency, and 3D World, is my go to source for VR
                (Virtual Reality), 3D Architectural Renderings and animations. I
                highly recommend them!”
              </p>
              <p>Conrad Strabone - E9digital Design Agency</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="referencia">
              <p>
                “Amazing work and great professionalism. Exceeded expectations
                from day one. This team has an incredible gift that helps bring
                your ideas to life and ideas I would have never though of. Very
                reliable and always punctual with deadlines”
              </p>
              <p>Ann Withey – Senior Architect AIA</p>
            </div>
          </SwiperSlide>
        </SliderComun>
      </ContentClientsSay>
      <ContentPhilosophyMobile>
        <h2>Our Philosophy</h2>
        <SliderComun pagination={true}>
          <SwiperSlide>
            <p className="title-philosophy">PASSION</p>
            <p className="content-philosophy">
              We are passionate about creative visual disciplines, technology
              and envisioning your ideas to life. We go the extra mile to really
              understand your expectations, and we don’t take shortcuts when it
              comes to creativity or quality. We thrive on producing great work
              and stunning projects.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <p className="title-philosophy">PARTNERSHIP</p>
            <p className="content-philosophy">
              Our studio has a strong culture of great customer service and
              honest, sustainable partnerships with our clients. We are not
              looking for a large one-time profit; we are here for the long
              haul. You will find that we are willing and able to mesh well with
              your existing team, and we make ourselves available. At the end of
              the day, we’re here for you.
            </p>
          </SwiperSlide>
          <SwiperSlide>
            <p className="title-philosophy">EFFICIENCY</p>
            <p className="content-philosophy">
              Our business practices and processes are constantly measured and
              evaluated to provide strong content that adds value to your
              strategy. We avoid wasted efforts and unnecessary friction. Our
              goal is to get you the best result. This is why we deliver your
              vision effectively, on time, and at a competitive cost.
            </p>
          </SwiperSlide>
        </SliderComun>
      </ContentPhilosophyMobile>
      <ContentPhilosophy>
        <h2>Our Philosophy</h2>
        <div className="philosophy">
          <p>
            <span>PASSION</span> – We are passionate about creative visual
            disciplines, technology and envisioning your ideas to life. We go
            the extra mile to really understand your expectations, and we don’t
            take shortcuts when it comes to creativity or quality. We thrive on
            producing great work and stunning projects.
          </p>
          <p>
            <span>PARTNERSHIP</span> – Our studio has a strong culture of great
            customer service and honest, sustainable partnerships with our
            clients. We are not looking for a large one-time profit; we are here
            for the long haul. You will find that we are willing and able to
            mesh well with your existing team, and we make ourselves available.
            At the end of the day, we’re here for you.
          </p>
          <p>
            <span>EFFICIENCY</span> – Our business practices and processes are
            constantly measured and evaluated to provide strong content that
            adds value to your strategy. We avoid wasted efforts and unnecessary
            friction. Our goal is to get you the best result. This is why we
            deliver your vision effectively, on time, and at a competitive cost.
          </p>
        </div>
        <div className="boton_philosophy">
          {/* <Button target='_blank' href='https://calendly.com/3dworld ' absolute padding subrayado>Schedule a Call</Button> */}
          {mobile && (
            <Button mobile padding subrayado absolute>
              <Link target="_blank" to="https://calendly.com/3dworld/15minutes">
                Schedule a Call
              </Link>
            </Button>
          )}
          {!mobile && (
            <Button padding subrayado absolute philosophy>
              <Link target="_blank" to="https://calendly.com/3dworld/15minutes">
                Schedule a Call
              </Link>
            </Button>
          )}
          {/* <div className='subrayado philosophy_mobile'></div> */}
        </div>
      </ContentPhilosophy>
      <ContentTeam>
        <img
          src="/assets/img/NYC_Rendering_Studio_3D_World_renderings_Inc.jpg"
          alt="NYC Rendering Studio 3D World renderings, Inc.jpg"
        />
      </ContentTeam>
      <ContentQuestions>
        <h2>Frequently Asked Questions</h2>
        <FaqList />
      </ContentQuestions>
    </div>
  );
};

export default Home;
