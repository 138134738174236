import { useEffect, useRef, useState } from "react";
import { WhatWeDoContainer } from "./WhatWeDoStyled";
import { Link } from "react-router-dom";

const DEFAULT_SERVICES_TRANSITION_TIMEOUT = 3000;
const YELLOW_INDICATOR_OFFSET = 8;

const servicesItems = [
  {
    id: "Rendering",
    label: "Rendering",
    link: "/Photorealistic-Rendering-Services",
  },
  {
    id: "Animation",
    label: "Animation",
    link: "/Animation-Services",
  },
  {
    id: "VR",
    label: "Virtual Reality",
    link: "/Virtual-Reality-Services",
  },
  {
    id: "Interactive",
    label: "Interactive",
    link: "/Interactive-Services",
  },
  {
    id: "Branding",
    label: "Branding",
    link: "/Branding-Services",
  },
  {
    id: "VFX",
    label: "VFX",
    link: "/VFX-Services",
  },
];

export const WhatWeDo = () => {
  const [currentServiceIndex, setCurrentServiceIndex] = useState(0);

  const timerRef = useRef(null);
  const servicesRefs = useRef([]);

  const getIndicatorStyles = () => {
    const activeServiceRef = servicesRefs.current?.[currentServiceIndex];

    if (!activeServiceRef) return;

    return {
      width: activeServiceRef.offsetWidth || 0,
      top:
        activeServiceRef.offsetTop +
        activeServiceRef.offsetHeight -
        YELLOW_INDICATOR_OFFSET,
    };
  };

  const stopTimer = () => {
    if (!timerRef.current) return;
    clearInterval(timerRef.current);
  };

  const startTimer = () => {
    clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      setCurrentServiceIndex((prevValue) => {
        if (prevValue === servicesRefs.current.length - 1) {
          return 0;
        }

        return prevValue + 1;
      });
    }, DEFAULT_SERVICES_TRANSITION_TIMEOUT);
  };

  const handleItemHover = (index) => {
    stopTimer();
    setCurrentServiceIndex(index);
  };

  // Effect to start animating the yellow indicator
  useEffect(() => {
    if (!servicesRefs.current.length) return;

    startTimer();

    return () => {
      stopTimer();
    };
  }, []);

  return (
    <WhatWeDoContainer>
      {servicesItems.map(({ id }, index) => (
        <img
          alt={`service-${id}`}
          key={`overlay-image-${index}`}
          className={`overlay-image ${
            index !== currentServiceIndex ? "is-hidden" : null
          }`}
          src={`/assets/img/what-we-do/${id}.jpg`}
        />
      ))}
      <div className="container">
        <div>
          <h3>What We Do</h3>
          <p>
            We make experiencing <br /> your ideas possible.
          </p>
        </div>
        <div className="services" onMouseLeave={() => startTimer()}>
          <div
            style={{
              ...getIndicatorStyles(),
            }}
            className="yellow-indicator"
          />
          {servicesItems.map(({ label, link }, index) => (
            <Link
              to={link}
              onMouseEnter={() => handleItemHover(index)}
              ref={(ref) => (servicesRefs.current[index] = ref)}
              key={label}
            >
              {label}
            </Link>
          ))}
        </div>
      </div>
    </WhatWeDoContainer>
  );
};

const isMobileViewport = () => {
  return window.innerWidth <= 550;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isMobileViewport());

  useEffect(() => {
    window.addEventListener("resize", function () {
      setIsMobile(isMobileViewport());
    });
  }, []);

  return isMobile;
};
